body {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5em;
}

.page-wrapper {
	color: #696973;
	font-family: inherit;
}

h1 {
	color: #354049;
	padding-top: 0.75rem;
	background-size: 70px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	line-height: 3rem;
	font-size: 2.6rem;
}

h2 {
	margin-top: 2rem;
	margin-bottom: 0.75rem;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #354049;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
}

h3,
.inside-right-sidebar-column h4 {
	margin-top: 2rem;
	margin-bottom: 0.75rem;
	background-size: 35px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #354049;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

h4 {
	margin-top: 1.875rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #354049;
	font-size: 1.375rem;
	line-height: 1.875rem;
	font-weight: 400;
}

.inside-right-sidebar-column h4,
.inside-right-sidebar-column h3 {
	font-size: 1.25rem;
	line-height: 1.625rem;
	margin-top: 0rem;
	color: #354049;
}

h5 {
	margin-top: 1.75rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	color: #354049;
}

h6 {
	margin-top: 1.625rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #354049;
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: 400;
}

p {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
}

.button,
.primary,
.secondary,
.tertiary,
.inside-content-mine button[type="submit"],
#jobs_module.cms_form .cms_form_button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.625em 1.25em;
	border-radius: 4px;
	background-color: #c22026;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.875rem;
	line-height: 1.25em;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	color: white !important;
	box-shadow: none;
}

.primary,
.secondary,
.tertiary,
.inside-content-mine button[type="submit"] {
	padding: 0.625em 1.25em !important;
	border: 0 none !important;
	box-shadow: none !important;
}

.button:hover {
	background-color: #76818c;
}

.primary:hover,
.inside-content-mine button[type="submit"]:hover {
	background-color: #76818c !important;
}

.button.secondary-button,
.secondary,
.tertiary {
	margin-right: 1rem;
	margin-left: 1rem;
	background-color: #354049;
}

.button.secondary-button:hover {
	background-color: #dd2a37;
}

.secondary:hover,
.tertiary:hover {
	background-color: #dd2a37 !important;
}

.inside-main-content-column .inside-content-mine a.primary,
.inside-main-content-column .inside-content-mine a.secondary,
.inside-main-content-column .inside-content-mine a.tertiary {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-section {
	padding-top: 3rem;
	background-color: #22272c;
	color: #cad4de;
	font-size: 0.875rem;
	position: relative;
}

.desktop-navigation-menu {
	margin-top: 4px;
}

.nav-bar-section {
	position: absolute;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease 0s;
}

.logo-brand-wrap {
	min-width: 0px;
	text-decoration: none;
	display: flex;
	height: 100%;
	align-items: center;
	margin-top: 7px;
	margin-bottom: 4px;
	padding-top: 0px;
	background-color: transparent;
}

.nav-link.two-part-button-link {
	position: relative;
	z-index: 2;
	display: block;
	width: 80%;
	max-width: 80%;
	float: left;
}

.nav-link.dropdown-list-link:hover {
	color: white;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link {
	padding-right: 0.75rem;
	padding-left: 0.625rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	float: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	color: #cad4de;
	font-size: 1rem;
	line-height: 1.375em;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link:hover {
	background-color: rgba(0, 0, 0, 0.14);
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #fff;
}

.nav-link.nav-dropdown-list-link.w--current {
	background-color: rgba(0, 0, 0, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #fff;
}

.nav-dropdown {
	position: relative;
	margin-right: 2px;
	margin-left: 2px;
	float: left;
}

.nav-dropdown-label-text {
	position: relative;
	margin-right: 0rem;
	float: left;
}

.nav-two-part-button-wrap {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.nav-two-part-button-dropdown-wrap {
	position: relative;
	left: 0px;
	top: 0px;
	z-index: 1;
	width: 100%;
}

.nav-two-part-button-dropdown-toggle {
	width: 20%;
	max-width: 20%;
	padding: 0.75rem 0px;
	float: right;
	border-left: 1px solid rgba(255, 255, 255, 0.09);
	text-align: center;
}

.nav-two-part-button-icon {
	display: block;
	width: 8px;
	height: 1.25rem;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.125rem;
	opacity: 0.5;
	float: right;
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translate(0px, -50%);
}

.nav-two-part-button-dropdown-list {
	position: relative;
	left: 0px;
	top: 0px;
	float: left;
}

.nav-two-part-button-dropdown-list.w--open {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
}

.nav-link {
	padding: 0rem;
	float: left;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.nav-link:hover {
	color: #c7171e;
}

.navigation-link {
	font-family: 'Roboto Condensed', sans-serif;
	color: #c22026;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-decoration: none;
	display: block;
	padding: 0.75rem 0.75rem;
	border-bottom: 3px solid #d8e1e5;
	background-color: transparent;
}

.navigation-link:hover,
.nav-link.active .navigation-link,
.navigation-link.active-item {
	color: #000000;
	border-bottom: 3px solid #dd2a37;
}

.nav-link.active {
	box-shadow: none;
}

.nav-dropdown-list {
	position: absolute;
	top: 44px;
	z-index: 100;
	min-width: 200px;
	border-top: 3px solid #c7171e;
	background-color: #0f3050;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
}

.logo-image {
	width: 218px;
	margin-top: -2px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.link,
.inside-main-content-column a,
.inside-main-content-column p a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #c22026;
	text-decoration: underline;
	cursor: pointer;
}

.link:hover,
.inside-main-content-column a:hover {
	color: #696973;
	text-decoration: none;
}

.inside-main-content-column .inside-content-mine a {
	text-decoration: underline;
	color: #c22026;
}

.inside-main-content-column .inside-content-mine a:hover {
	text-decoration: none;
	color: #696973;
}

.link.breadcrumb-link {
	display: inline-block;
	float: left;
	color: #5c7a94;
	border-bottom: none;
}

.link.breadcrumb-link:hover {
	color: #b8131b;
}

.inside-main-content-column .inside-content-mine .link.breadcrumb-link {
	text-decoration: none;
	border-bottom-color: transparent;
}

.inside-main-content-column .inside-content-mine .link.breadcrumb-link:hover {
	text-decoration: none;
	border-bottom-color: rgba(93, 116, 140, 0.6);
}

.link.footer-link {
	color: white;
	text-decoration: none;
	background-color: transparent;
	border-bottom: 1px solid transparent;
}

.link.footer-link:hover {
	color: #fffbbb;
	cursor: pointer;
}

.link.content-box-link {
	color: #354049;
	cursor: pointer;
}

.link.content-box-link:hover {
	color: #dd2a37;
	cursor: pointer;
}

.list,
.inside-main-content-column ol,
.inside-main-content-column ul:not(.breadcrumbs) {
	margin-top: 0.375em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.breadcrumbs-column {
	height: 40px;
}

.breadcrumbs {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding-left: 1px;
	font-family: 'Roboto Condensed', sans-serif;
	color: #5c7a94;
	font-size: 0.75rem;
	line-height: 1.25em;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.breadcrumb-item {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.breadcrumb-item-divider {
	margin-left: 0.5rem;
	float: right;
}

.split-50 {
	width: 50%;
	float: left;
}

.column.tools {
	padding-right: 0px;
	padding-left: 0px;
}

.row.middle-content-box-row {
	margin-top: 3rem;
	margin-bottom: 3rem;
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-top: 1px solid rgba(0, 42, 84, 0.09);
	border-bottom: 1px solid rgba(0, 42, 84, 0.09);
}

.footer-section-signoff {
	padding-bottom: 1.5rem;
	padding-top: 0.6rem;
	background-color: #191d21;
}

.footer-section-signoff .center-column {
	text-align: center;
}

.footer-signoff-grip {
	width: 130px;
	height: 32px;
	float: right;
	opacity: 0.75;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.footer-top-container {
	padding-bottom: 1.8rem;
}

.footer-signoff-grip:hover {
	opacity: 1;
}

#GRIPFooterLogo {
	width: 130px;
	height: 32px;
	float: right;
	opacity: 0.75;
	padding-top: 0 !important;
	margin-top: -4px;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

#GRIPFooterLogo a {
	background-color: transparent;
	cursor: pointer;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

#GRIPFooterLogoText {
	margin-bottom: -11px;
	color: #ffffff !important;
	background-color: #191d21 !important;
}

.copyright-text {
	display: inline-block;
	margin-top: 0.5rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #d8e1e5;
	font-size: 0.75rem;
	line-height: 1rem;
}

.footer-list {
	border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-list-item {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-list-link {
	display: block;
	padding: 0.875em 1rem;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_yellow.svg');
	background-position: 0px 50%;
	background-size: 0.5rem 0.5rem;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	line-height: 1.375em;
	text-decoration: none;
	background-color: transparent;
	border-bottom: 1px solid transparent;
}

.footer-list-link:hover {
	color: #fffbbb;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_blue.svg');
}

.footer-column-title {
	margin-top: 0rem;
	padding-top: 0.75em;
	padding-bottom: 0.5rem;
	background-image: url('/20150810114905/assets/images/yellow_tab.png');
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
	font-weight: 400;
}

.footer-section .social-links {
	border-top: 1px solid rgba(255, 255, 255, 0.12);
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
	margin-bottom: 45px;
	display: block;
	width: 100%;
	text-align: center;
	padding-bottom: 10px;
	padding-top: 10px;
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;
}

.footer-section .social-links ul {
	list-style-type: none;
	margin: 0px auto;
	padding: 0px;
}

.footer-section .social-links ul li {
	display: inline;
	margin: 0px;
	padding: 0px;
}

.footer-section .social-links ul li a {
	margin: 2vw;
}

.footer-section .social-links ul li img {
	margin-right: 0px;
	margin-left: 0px;
	width: 36px;
	height: 36px;
}

.slider-section {
	padding-top: 110px;
}

.slider {
	height: 500px;
	background-color: transparent;
}

.slider-arrow {
	height: 20%;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: all 500ms ease;
	font-size: 2.25rem;
	color: #6e7c8a;
}

.slider-arrow:hover {
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0.8;
	color: white;
}

.slider-indicators {
	height: 2rem;
	opacity: 1;
	font-size: 0.625rem;
}

.slider-slide {
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-slide.slide-1 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-121198814.jpg');
}

.slider-slide.slide-2 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-135087111.jpg');
}

.slider-slide.slide-3 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-178923149_crop.jpg');
}

.w-shadow.cycle-pager {
	position: relative;
	margin-top: -20px;
	z-index: 100;
}

.content-box-section {
	padding-bottom: 4rem;
}

.content-box-section a {
	color: #356aa0;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	-webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease !important;
	transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease !important;
}

.content-box-section a:hover {
	color: #b8131b;
	text-decoration: none;
}

.content-box-section p a {
	border-bottom: 1px solid;
	border-bottom-color: rgba(84, 159, 196, 0.3);
}

.content-box-section p a:hover {
	border-bottom-color: rgba(93, 116, 140, 0.6);
}

.content-box-section h3:not(.content-box-title) a {
	border-bottom: 1px solid;
	border-bottom-color: rgba(84, 159, 196, 0.3);
}

.content-box-section h3:not(.content-box-title) a:hover {
	border-bottom-color: rgba(93, 116, 140, 0.6);
}

.content-box-section ul {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.content-box-section ol {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.content-box-section li {
	margin-bottom: 0.25em;
}

.content-box-section li a {
	border-bottom: 1px solid;
	border-bottom-color: rgba(84, 159, 196, 0.3);
	-webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease;
	transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease;
}

.content-box-section li a:hover {
	border-bottom-color: rgba(93, 116, 140, 0.6);
}

.content-box-wrap {
	font-size: 0.875rem;
	line-height: 1.5em;
}

.content-box-wrap .button,
.our-company-section-content-wrap .button {
	border: 2.5px solid #dd2a37;
	border-radius: 4px;
	cursor: pointer;
	background-color: #87171a;
	font-weight: 700;
}

.content-box-wrap .button:hover,
.our-company-section-content-wrap .button:hover {
	border-color: #fff;
	background-color: #76818c;
}

.content-box-image {
	width: 36%;
	min-height: 115px;
	float: left;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition-delay: initial;
	transition-delay: initial;
}

.content-box-image:hover {
	opacity: 1;
}

.content-box-image.businesses {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-162631615_edit_768.jpg');
}

.content-box-image.individuals {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-57568504_768.jpg');
}

.content-box-text-wrap {
	width: 60%;
	padding-right: 1rem;
	float: right;
}

.cb-list h3,
.content-box-title {
	margin-top: 0.5rem;
	font-size: 1.75rem;
	line-height: 2.25rem;
	background-image: url("/20150810114905/assets/images/yellow_line_accent.svg");
	background-size: 35px 2px;
	padding-top: 0.75rem;
}

.tools-section {
	margin-top: 0.25rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #d8e1e5;
}

.home-tools-link-wrap {
	position: relative;
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
	background-image: url('/20150810114905/assets/images/icon_arrow-link.svg');
	background-position: 95% 52%;
	background-size: 0.875rem 0.875rem;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #c22026;
	font-size: 1.5rem;
	line-height: 1.25em;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	border-right: 4px solid #dd2a37;
}

.home-tools-link-wrap:hover {
	color: #fffbbb;
	border-right-color: #fffbbb;
	background-color: #1e2429;
}

.home-tools-link-image {
	position: relative;
	width: 4.5rem;
	height: 4.5rem;
	float: left;
	background-color: #354049;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: 90% 90%;
	background-repeat: no-repeat;
	border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.home-tools-link-image.proposal {
	background-image: url('/20150810114905/assets/images/what-we-do.svg');
}

.home-tools-link-image.claim-icon {
	background-image: url('/20150810114905/assets/images/testimonials.svg');
}

.home-tools-link-image.tools-icon {
	background-image: url('/20150810114905/assets/images/mission-and-vision.svg');
}

.home-tools-link-text {
	position: absolute;
	left: 5.5rem;
	top: 50%;
	float: left;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	text-transform: none;
}

.specialty-programs-section-list,
.cb-list ul {
	margin-top: 3.5rem;
	padding-left: 0;
}

.specialty-programs-list-item,
.cb-list li {
	display: inline-block;
	width: 44%;
	margin-right: 3%;
	margin-left: 3%;
	float: left;
	border-bottom: 1px solid rgba(0, 42, 84, 0.14);
	text-align: left;
}

.specialty-programs-list-link,
.cb-list li a {
	display: block;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 0.875rem;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_red.svg');
	background-position: 2px 53%;
	background-size: 0.5rem 0.5rem;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.specialty-programs-list-link:hover,
.cb-list li a:hover {
	color: #b8131b;
}

.home-tools-row {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.our-company-section {
	position: relative;
	height: auto;
	padding-top: 4rem;
	padding-bottom: 4rem;
	margin-bottom: 0.25rem;
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.our-company-section-content-wrap {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	display: block;
	width: 85%;
	max-width: 940px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 4rem;
	background-color: rgba(53, 64, 73, 0.8);
	color: #fff;
	text-align: center;
}

.our-company-section-title {
	margin-top: 0.75rem;
	padding-top: 1rem;
	background-image: url('/20150810114905/assets/images/yellow_tab.png');
	background-position: 50% 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
}

.our-company-section-text {
	font-size: 1.125rem;
	line-height: 2rem;
}

.slider-content-overlay-wrap {
	position: absolute;
	left: 50%;
	top: 50%;
	right: auto;
	display: block;
	width: auto;
	max-width: 728px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 4rem;
	background-color: rgba(53, 64, 73, 0.8);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.fixed-bknd-section-texture {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	margin-bottom: 0rem;
	background-attachment: fixed;
	opacity: 0.5;
	background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.slider-content-overlay-wrap .button {
	background-color: #87171a;
	cursor: pointer;
	font-weight: 700;
	border: 2.5px solid #dd2a37;
	color: #fff;
}

.slider-content-overlay-wrap .button:hover {
	border-color: #fff;
	color: #fff;
	background-color: #76818c;
	cursor: pointer;
}

.slider-title {
	margin-top: 0.75rem;
	padding-top: 1rem;
	background-image: url('/20150810114905/assets/images/yellow_tab.png');
	background-position: 50% 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
	font-weight: 400;
	text-transform: uppercase;
}

.slider-text {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
}

.footer-icon {
	width: 3rem;
	height: 3rem;
	margin-top: 1rem;
	margin-bottom: 0.25rem;
}

.content-box-image-link {
	width: 100%;
	height: 200px;
	margin-bottom: 0.5rem;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-box-image-link.image-1 {
	background-image: url('/20150810114905/assets/images/Bond-picture_edit.jpg');
}

.content-box-image-link.image-2 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-177792762_edit_768.jpg');
}

.content-box-image-link.image-3 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-465065737_768.jpg');
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #ffffff;
	font-size: 0.75rem;
	line-height: 1rem;
	text-decoration: none;
	background-color: transparent;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	color: #fffbbb;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-left: 0.75rem;
}

.footer-quick-links-wrap {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.nav-search-icon-btn {
	width: 3.25rem;
	height: 3rem;
	min-width: 0px;
	float: right;
	border-left: 0px solid rgba(0, 42, 84, 0.25);
	background-image: url('/20150810114905/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 1rem 1rem;
	background-repeat: no-repeat;
	opacity: 0.35;
	-webkit-transition: opacity 400ms ease !important;
	transition: opacity 400ms ease !important;
}

.nav-search-icon-btn:hover {
	opacity: 1;
}

.header-search-wrap {
	position: relative;
	z-index: 90;
	display: none;
	width: 100%;
	margin: -15px auto -1rem;
	padding-top: 0px;
	float: left;
	clear: both;
	transition: all 500ms ease 0s;
	padding-bottom: 31px;
}

.header-search-field {
	width: 85% !important;
	height: 3rem;
	margin-bottom: 0px;
	padding: 0.25rem 0.75rem;
	float: left;
	border-style: solid none solid solid;
	border-width: 1px;
	border-color: rgba(0, 42, 84, 0.09);
	border-radius: 0px;
	font-size: 1.125rem;
}

.header-search-form-button {
	width: 15%;
	height: 3rem;
	min-width: 0px;
	float: left;
	background-color: #696973;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_wht_50.svg');
	background-position: 50% 50%;
	background-size: 1.25rem;
	background-repeat: no-repeat;
	border: 0 none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	text-indent: -9999px;
}

.header-search-form-button:hover {
	background-color: #c22026;
}

.header-search-session {
	height: 0px;
	background-color: transparent;
}

.content-box-section a.content-box-learn-more-link {
	display: inline-block;
	margin-top: 0.5em;
	padding-left: 0.875em;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_red.svg');
	background-position: 0px 50%;
	background-size: 0.625em 0.625em;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #c22026;
	font-weight: 700;
	font-size: 0.9375rem;
	line-height: 1.25em;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 1px solid;
	border-bottom-color: transparent;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
}

.content-box-section a.content-box-learn-more-link:hover {
	box-shadow: 0 1px 0 0 #d8e1e5;
}

.footer-nav-list {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
}

.footer-nav-list-item {
	display: inline-block;
	margin-right: 2rem;
	margin-left: 2rem;
}

.footer-nav-list-link {
	display: block;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #76818c;
	font-size: 1rem;
	text-decoration: none;
}

.footer-nav-list-link:hover {
	color: #69c0ff;
	cursor: pointer;
}

.footer-nav-list-link.w--current {
	color: #cad4de;
	cursor: pointer;
}

.footer-nav-list-link.w--current:hover {
	color: #69c0ff;
	cursor: pointer;
}

.inside-content-section {
	background: #d8e1e5;
}

.inside-content-header-section {
	background-color: #354049;
	position: relative;
	height: 200px;
}

.left-nav-list.left-nav-nested-list {
	padding-bottom: 1rem;
	padding-left: 0.625rem;
	box-shadow: rgba(0, 42, 84, 0.1) 0px -1px 0px 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.left-nav-list-item {
	margin-top: 1px;
	margin-bottom: 1px;
	box-shadow: rgba(0, 42, 84, 0.09) 0px 1px 0px 0px;
}

.left-nav-list.left-nav-nested-list ul {
	padding-bottom: 0.7rem;
}

.left-nav-link {
	box-shadow: transparent 3px 0 0 0 inset;
	display: block;
	padding: 0.625rem 1rem 0.625rem 0.75rem;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #696973;
	font-size: 1.125rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.left-nav-link:hover {
	color: #354049;
	background-color: #e8edef;
	box-shadow: #c22026 3px 0 0 0 inset;
}

.left-nav-link:active {
	color: #354049;
	box-shadow: #c22026 3px 0 0 0 inset;
}

.left-nav-link.w--current,
.left-nav-link.active {
	color: #354049;
	background-color: #e8edef;
	box-shadow: #c22026 3px 0 0 0 inset;
}

.left-nav-link.nested-nav-link {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #696973;
	font-size: 1rem;
	line-height: 1.25rem;
}

.left-nav-link.nested-nav-link:hover {
	color: #354049;
	cursor: pointer;
}

.left-nav-link.nested-nav-link.w--current,
.left-nav-link.nested-nav-link.active {
	color: #354049;
	background: #e8edef;
}

.left-nav-list-title-link-wrap {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	margin-left: 0.625rem;
	padding-top: 0.75em;
	padding-bottom: 0.75rem;
	background-image: url('/20150810114905/assets/images/red_tab.png');
	background-position: 0px 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #696973;
	font-size: 1.375rem;
	line-height: 1.625rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-list-title-link-wrap:hover {
	color: #696973;
	cursor: pointer;
}

.left-nav-list-title {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

.form-wrap {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.form-field-label {
	margin-bottom: 2px;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-weight: 400;
	text-transform: uppercase;
}

.form-text-field {
	margin-bottom: 16px;
	padding-right: 10px;
	padding-left: 10px;
}

.form-text-field.right-sidebar-search-field {
	height: 34px;
	margin-bottom: 0px;
}

.form-success {
	padding-top: 16px;
	background: #606f1e;
	color: #ffffff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	text-align: center;
}

.form-error {
	padding-top: 16px;
	font-size: 1.25rem;
	background: #b80b0b;
	color: white;
	line-height: 1.75rem;
	text-align: center;
}

.left-nav-column-wrap {
	padding-top: 1.5rem;
	padding-bottom: 6rem;
	border-right: 1px solid rgba(0, 42, 84, 0.1);
}

.inside-right-sidebar-column {
	border-left: 1px solid rgba(0, 42, 84, 0.11);
}

.inside-main-content-nested-column-content-wrap {
	margin-right: 1.25rem;
}

.inside-right-sidebar-section-wrap {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	margin-left: 1.25rem;
}

.inside-right-sidebar-section-title {
	margin-top: 0rem;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

.inside-right-sidebar-list-item {
	margin-bottom: 0.75rem;
}

.inside-content-bknd {
	display: block;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	background-color: white;
	min-height: 500px;
	position: relative;
	padding-top: 0em;
	box-shadow: #cad4de 0 -4px 0 0;
}

.header-content-wrap {
	position: relative;
	z-index: 100;
}

.footer-logos-wrap {
	width: 25%;
	float: left;
}

.w-row.footer-links {
	padding-top: 1.5rem;
}

.nav-bar-container {
	height: 110px;
}

.menu-container {
	width: 60%;
	display: inline-block;
	float: right;
}

.at-expanding-share-button-desktop a {
	background-color: transparent;
	cursor: pointer;
}

.nav-menu-wrap {
	background-color: #354049;
	padding-bottom: 30rem;
}

@media (max-width: 991px) {
	.google-translate-container {
		line-height: 1.5rem;
	}

	.menu-container {
		width: auto;
		margin-top: 31px;
	}

	.footer-section {
		padding: 2.5rem 1rem 1rem;
		font-size: 1.125rem;
		line-height: 2.25rem;
	}

	.nav-bar-section {
		padding-top: 0rem;
		padding-bottom: 0rem;
		position: relative;
	}

	.nav-bar-container {
		max-width: 100%;
		margin-right: 0px;
		margin-left: 0px;
		height: auto;
	}

	.logo-brand-wrap {
		margin-top: 10px;
		margin-bottom: 0rem;
		margin-left: 1rem;
		height: auto;
		display: block;
	}

	.nav-menu-wrap {
		padding-bottom: 30rem;
		background-color: #354049;
	}

	.nav-link.two-part-button-link {
		border-bottom-style: none;
	}

	.nav-link.two-part-button-link:hover {
		box-shadow: #9fb833 5px 0px 0px 0px inset;
	}

	.nav-link.dropdown-list-link {
		overflow-x: hidden;
		overflow-y: hidden;
		max-width: 100%;
	}

	.nav-link.dropdown-list-link:hover {
		box-shadow: #9fb833 5px 0px 0px 0px inset;
	}

	.nav-two-part-button-wrap {
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		border-bottom: 1px solid rgba(255, 255, 255, 0.09);
	}

	.nav-two-part-button-dropdown-wrap {
		position: relative;
	}

	.nav-two-part-button-dropdown-toggle {
		min-height: 100%;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.nav-two-part-button-dropdown-toggle:hover {
		background-color: rgba(0, 0, 0, 0.09);
		min-height: 100%;
	}

	.nav-two-part-button-dropdown-toggle.w--open {
		background-color: rgba(0, 0, 0, 0.2);
		min-height: 100%;
	}

	.nav-two-part-button-dropdown-toggle.w--open:hover {
		background-color: rgba(0, 0, 0, 0.29);
		min-height: 100%;
	}

	.nav-two-part-button-icon {
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translate(0px, -50%);
	}

	.nav-two-part-button-dropdown-list.w--open {
		max-width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.09);
		background-color: rgba(0, 0, 0, 0.2);
	}

	.nav-link {
		display: block;
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0px;
		float: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		color: #cad4de;
		font-size: 1.125rem;
		font-weight: 300;
		letter-spacing: 1px;
	}

	.nav-link:hover {
		box-shadow: none;
		color: #e31822;
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.29);
		box-shadow: #9fb833 5px 0px 0px 0px inset;
		color: white;
	}

	.nav-menu-button {
		padding: 1.25rem 1.25rem 2.5rem 1.25rem;
		border-left: 1px solid rgba(0, 42, 84, 0.1);
		font-family: 'Roboto Condensed', sans-serif;
		color: #354049;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.nav-menu-button:hover {
		background-color: #c22026;
		box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 0px 0px;
		color: white;
	}

	.mm-wrapper_opened .mobile-menu-button {
		background-color: #c22026;
		box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 0px 0px;
		color: white;
	}

	.nav-menu-button-icon {
		width: 1.25rem;
		height: 1.375rem;
		opacity: 0.25;
	}

	.breadcrumb-item {
		margin-right: 0.75rem;
	}

	.breadcrumb-item-divider {
		margin-left: 0.75rem;
	}

	.column.footer-column {
		margin-bottom: 2.5rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.09);
	}

	.footer-list-link {
		line-height: 1.5rem;
	}

	.footer-column-title {
		font-size: 1.5rem;
	}

	#GRIPFooterLogoText {
		margin-bottom: -25px;
	}

	.slider-section {
		padding-top: 0px;
	}

	.slider {
		height: 440px;
	}

	.slider-arrow {
		width: 7%;
	}

	.content-box-section {
		padding-bottom: 3rem;
	}

	.content-box-wrap {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.content-box-image {
		width: 45%;
		min-height: 200px;
		float: right;
	}

	.content-box-text-wrap {
		width: 52%;
		margin-top: 0.5rem;
		float: left;
	}

	.tools-section {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.home-tools-link-wrap {
		background-size: 0.75rem 0.75rem;
		font-size: 1.4rem;
		line-height: 1.25em;
	}

	.specialty-programs-section-list,
	.cb-list ul {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
	}

	.specialty-programs-list-item,
	.cb-list li {
		width: 47%;
		margin-left: 0%;
	}

	.specialty-programs-row {
		margin-top: 0rem;
		padding-top: 0rem;
		border-top-style: none;
	}

	.slider-content-overlay-wrap {
		padding: 1rem 2rem;
	}

	.slider-title {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.slider-text {
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.content-box-image-link {
		width: 45%;
		float: left;
	}

	.content-box-image-link.image-1 {
		float: left;
	}

	.middle-content-box-text-wrap {
		width: 50%;
		margin-top: 1rem;
		margin-left: 3%;
		float: left;
	}

	.footer-quick-links-logo-link {
		margin-right: 2.25rem;
	}

	.footer-telephone-link {
		display: inline !important;
		color: #a8b4bf !important;
		background-color: transparent;
	}

	.nav-search-icon-btn {
		width: 3rem;
		height: 3rem;
		margin-left: 0rem;
		border-left-style: none;
		background-size: 1.25rem 1.25rem;
		margin-top: 0px;
	}

	.header-search-wrap {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding: 0.75rem;
		border-top: 1px solid rgba(0, 42, 84, 0.09);
		overflow: hidden;
	}

	.footer-nav-list-item {
		margin-right: 1rem;
		margin-left: 1rem;
		line-height: 1.5rem;
	}

	.footer-nav-list-link {
		font-size: 1rem;
	}

	.inside-right-sidebar-column {
		margin-top: 2rem;
		padding-top: 1.5rem;
		border-top: 1px solid rgba(0, 42, 84, 0.11);
		border-left-style: none;
		border-left-width: 0px;
	}

	.inside-main-content-nested-column-content-wrap {
		margin-right: 0rem;
	}

	.inside-right-sidebar-section-wrap {
		margin-left: 0rem;
	}

	.inside-content-bknd {
		max-width: 840px;
	}

	.inside-content-header-section {
		height: 100px;
	}

	.inside-right-sidebar-section-wrap {
		margin-top: 0px;
	}
}

@media (max-width: 991px) {
	.footer-quick-links-wrap {
		margin-left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.footer-column-title.quick-links {
		margin-left: 0px;
	}

	.footer-section-signoff {
		padding-top: 0rem;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.125rem;
	}

	.footer-section {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.logo-brand-wrap {
		padding-left: 0px;
	}

	.nav-menu-button {
		padding-right: 1.125rem;
		padding-left: 1.125rem;
	}

	.column.tools {
		padding: 0.25rem 1.25rem;
	}

	.footer-section-signoff {
		margin-top: 0rem;
		text-align: center;
	}

	.footer-signoff-grip {
		margin-top: 2rem;
		margin-right: 0rem;
		float: none;
	}

	#GRIPFooterLogo {
		float: none;
		margin: 2rem auto 0;
	}

	.slider {
		height: 400px;
	}

	.slider-arrow {
		display: none;
	}

	.content-box-section {
		padding-right: 0.625rem;
		padding-left: 0.625rem;
	}

	.content-box-wrap {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.content-box-image {
		width: 100%;
		min-height: 280px;
		float: left;
	}

	.content-box-text-wrap {
		width: 100%;
		margin-top: 0.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.tools-section {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.home-tools-link-wrap {
		width: 100%;
		line-height: 1.25rem;
	}

	.specialty-programs-list-item,
	.cb-list li {
		display: block;
		width: auto;
		margin-right: 0%;
		margin-left: 0%;
		float: none;
	}

	.our-company-section-content-wrap {
		width: 95%;
		padding-right: 25px;
		padding-left: 25px;
	}

	.our-company-section-title {
		font-size: 1.5rem;
		line-height: 1.875rem;
	}

	.our-company-section-text {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slider-content-overlay-wrap {
		width: 85%;
	}

	.content-box-image-link {
		width: 100%;
		height: 280px;
		float: none;
	}

	.middle-content-box-text-wrap {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0%;
		float: none;
		font-size: 1rem;
	}

	.footer-signoff-link {
		font-size: 0.875rem;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-left: 0rem;
	}

	.footer-quick-links-wrap {
		width: 100%;
		padding-right: 0rem;
		float: none;
		clear: both;
		border-right-style: none;
	}

	.footer-quick-links-logo-link {
		display: inline-block;
		margin-top: 2rem;
		margin-right: 1rem;
		margin-left: 1rem;
		float: none;
	}

	.footer-link-logo-image.risk-pro-net {
		float: none;
	}

	.footer-nav-list-item {
		display: block;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		float: none;
	}

	.footer-nav-list-link {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.inside-main-content-column {
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer-logos-wrap {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
		text-align: center;
	}

	.social-links {
		margin-left: 10px;
		margin-right: 10px;
		width: auto;
	}

	.logo-image {
		width: 170px;
		margin-top: 10px;
	}
}

@media (max-width: 479px) {
	.row.middle-content-box-row {
		padding-top: 0px;
	}

	.footer-section {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
	}

	.breadcrumbs {
		margin-top: 1rem;
	}

	.split-50.footer-split {
		width: 100%;
	}

	.column.tools {
		padding-right: 0.8756rem;
		padding-left: 0.875rem;
	}

	.footer-list {
		margin-right: 0rem;
	}

	.slider {
		height: 320px;
	}

	.content-box-section {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
	}

	.content-box-image {
		min-height: 180px;
	}

	.content-box-text-wrap {
		width: 100%;
		float: left;
	}

	.specialty-programs-list-item,
	.cb-list li {
		margin-right: 0%;
		margin-left: 0%;
	}

	.our-company-section-content-wrap {
		width: 90%;
	}

	.content-box-image-link {
		height: 180px;
	}

	.header-search-field {
		width: 80% !important;
	}

	.header-search-form-button {
		width: 20%;
	}

	.inside-main-content-column {
		padding-right: 16px;
		padding-left: 16px;
	}

	.nav-search-icon-btn {
		margin-top: -7px;
		margin-right: 0px;
	}
}

html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

a[href^=tel] {
	color: #ffffff;
	font-family: inherit;
}

/* Tablesaw */
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: 0px solid;
	}
}

/* Print */
#printHeader,
#printFooter {
	display: none;
}

/* Header */
.nav-link.nav-dropdown-list-link.nav-section-header {
	border-bottom-width: 2px;
	border-left-style: none;
	color: rgba(255, 255, 255, 0.55);
	font-size: 1.125rem;
	font-weight: 400;
	letter-spacing: 0.03em;
	line-height: 1.25em;
	text-transform: uppercase;
	padding: 0.625rem 0.5rem;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link.nav-section-header:hover {
	background-color: transparent;
	color: #ffffff;
}

.nav-dropdown-list.w--open {
	padding-bottom: 0rem;
	padding-top: 0rem;
}

#ecommerceLinks {
	width: auto;
	height: 28px;
	float: right;
	margin-top: 8px;
	margin-bottom: 10px;
	margin-right: 165px;
}

#ecommerceLinks a.link {
	font-weight: 700;
	font-size: 0.75rem;
	text-align: right;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 0.75rem;
	color: #000000;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	border-right: 2px solid #ccc;
	padding: 0 0.5rem;
	float: left;
	margin-top: 9px;
	background-color: transparent;
}

#ecommerceLinks a.link.no-border {
	border-right: 0px;
}

#ecommerceLinks a.link:hover {
	color: #76818c;
	font-weight: 700;
}

.google-translate-container {
	position: absolute;
	right: 0;
	top: 7px;
}

.header-address {
	background: #dd2a37;
	text-align: center;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	padding: 7px 5px 7px 5px;
	font-size: 1rem;
	line-height: 1.5rem;
}

.header-address a {
	color: #fff;
	text-decoration: none;
	box-shadow: none;
	border-bottom: 0px;
	background-color: transparent;
}

.header-address .phone {
	font-size: 1.7rem;
	line-height: 2rem;
	text-decoration: none;
}

@media (max-width: 991px) {
	#ecommerceLinks {
		display: none;
	}

	#cart-link {
		position: relative;
	}

	.logo-image {
		margin-bottom: 10px;
	}

	.google-translate-container {
		position: static;
		width: 100%;
		top: 0;
		right: 0;
		text-align: center;
		padding: 7px 0px 7px 0px;
		border-bottom: 4px solid #cad4de;
	}
}

@media (max-width: 479px) {
	.header-address .phone {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}
}

/* Sticky header */
@media screen and (min-width: 992px) {
	.sticky.nav-bar-section {
		position: fixed;
		top: 0;
		padding-top: 7px;
		padding-bottom: 0.5rem;
		border-top: 2px solid #cad4de;
		background-color: #ffffff;
		width: 100%;
		border-bottom: 1px solid #cad4de;
	}

	.header-search-form {
		margin-top: 7px;
	}

	.sticky .header-content-wrap {
		margin-top: -9px;
	}

	.header-content-wrap {
		height: 100%;
	}

	.sticky .nav-dropdown-list {
		top: 44px;
	}

	.sticky .header-search-wrap {
		float: none;
	}
}

/* Mobile menu */
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mm-menu > .mm-listview {
	padding-top: 0px;
	padding-bottom: 0px;
}

.mm-listview > li .mm-btn_next {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mm-listview > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults)::after {
	border-bottom-width: 0px;
}

.mm-menu .w-nav-link.nav-a {
	color: #fff;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 1.125rem;
	line-height: 1.25rem;
	letter-spacing: 1px;
	font-weight: 300;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	background-color: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mm-menu .w-nav-link.nav-a:hover,
.mm-menu li.active .w-nav-link.nav-a {
	color: #ffffff;
	box-shadow: 5px 0 0 0 #d8e1e5 inset;
	background-color: #c22026;
	text-decoration: none;
}

.mm-menu .mm-listview > li > a.mm-btn_next::before {
	border-color: rgba(255, 255, 255, 0.1);
	border-width: 1px;
}

.mm-listview a.mm-btn_next + a,
.mm-listview > li > a {
	padding: 22px 5px 23px 20px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 20%;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 20%;
}

.mm-menu.mm-vertical .mm-listview > li > a.mm-btn_next::after {
	top: 26px !important;
}

.mm-menu.mm-vertical .mm-listview > li > a.mm-btn_next {
	height: 66px;
}

.mm-listview a.mm-btn_next::after,
.mm-listview a.mm-prev::before {
	border: 2px solid rgba(255, 255, 255, 0.3);
	height: 8px;
	width: 8px;
}

.mm-listview a.mm-btn_next:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listview a.mm-btn_next::after {
	border-bottom: medium none;
	border-right: medium none;
	right: 45%;
}

.mm-menu .mm-listview > li > a.mm-btn_next::after,
.mm-menu .mm-listview > li > a.mm-prev::before {
	border-color: #fff;
}

.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-btn_next::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > a.w-nav-link.nav-a.w--current {
	background-color: rgba(0, 0, 0, 0.29);
	box-shadow: 5px 0 0 0 #69c0ff inset;
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.mm-menu li.mm-opened .w-nav-link.nav-a:hover {
	background-color: rgba(0, 0, 0, 0.29);
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > a.mm-btn_next {
	background: rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > ul > li > a.mm-btn_next:hover {
	background-color: rgba(0, 0, 0, 0.29);
}

.mm-listview a.mm-prev::before {
	border-bottom: medium none;
	border-right: medium none;
	right: 45%;
}

.mm-listview a.mm-prev {
	padding-top: 42px !important;
	font-size: 1.125rem;
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > ul {
	background-color: rgba(0, 0, 0, 0.02);
}

.mm-menu.mm-vertical .mm-listview .mm-panel {
	padding: 0;
}

.mm-listview > li.mm-opened > ul > li {
	border-bottom: 0 none;
}

.mm-listview > li.mm-opened > ul > li > a.nav-a {
	background-color: rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mm-listview > li.mm-opened > ul > li > a.mm-btn_next {
	background-color: rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mm-listview > li.mm-opened > ul > li.mm-opened > a.mm-btn_next {
	background-color: rgba(0, 0, 0, 0.35) !important;
}

.mm-menu.mm-vertical .mm-listview li.mm-opened > ul > li.mm-opened > ul {
	background-color: rgba(0, 0, 0, 0.2);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.w--current):not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span:not(.w--current) {
	background: transparent;
}

.mm-menu .mm-listview > li > a.mm-prev {
	color: rgba(255, 255, 255, 0.55);
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-navbar {
	border: 1px solid rgba(255, 255, 255, 0.1);
	text-align: left;
	height: 60px;
}

.mm-navbar .mm-navbar__title {
	color: #fff !important;
	font-size: 1.125rem;
	padding-top: 19px;
	background-color: transparent;
}

.mm-navbar__btn:first-child {
	left: -5px;
	top: 9px;
	background-color: transparent;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: 0px;
}

/* Accesible Menu */
.accesible-navigation-menu ul {
	margin: 0px;
	list-style-type: none;
}

.accesible-navigation-menu li.nav-dropdown .container-wrapper {
	display: none;
	position: absolute;
	top: 44px;
	z-index: 100;
	min-width: 200px;
	padding-top: 3px;
}

.accesible-navigation-menu li.nav-dropdown.open .container-wrapper {
	display: block;
}

.accesible-navigation-menu .container-wrapper ul {
	background-color: #22272c;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
	transition: opacity 300ms;
	opacity: 0;
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-dropdown.open .container-wrapper ul {
	opacity: 1;
}

.accesible-navigation-menu .container-wrapper ul li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu .container-wrapper ul li a {
	padding-right: 0.75rem;
	padding-left: 0.625rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	float: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	color: #cad4de;
	font-size: 1rem;
	line-height: 1.375em;
	text-decoration: none;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	background-color: transparent;
}

.accesible-navigation-menu .container-wrapper ul li a:hover,
.accesible-navigation-menu .container-wrapper ul li a.w--current {
	background-color: #354049;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #fff;
	box-shadow: inset 3px 0 0 0 #f8f07a;
}

.accesible-navigation-menu .nav-dropdown.has-dropdown .navigation-link {
	padding-right: 0px;
}

.accesible-navigation-menu .nav-dropdown.has-dropdown .navigation-link:after {
	width: 8px;
	height: 8px;
	display: inline-block;
	content: "";
	opacity: 0.5;
	background-image: url("/20180703073046/assets/images/icon_arrow2_down_blk_50.svg");
}

/* Inside page */
.left-nav-reveal-button-wrap {
	display: none;
	padding: 0.75em 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px solid #d9e3ec;
	border-bottom: 1px solid #d9e3ec;
	color: #354049;
	font-size: 1.3rem;
	cursor: pointer;
	font-family: 'Roboto Condensed', sans-serif;
}

.left-nav-reveal-button-text {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding-top: 0.125em;
}

.left-nav-reveal-button-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 6px 6px 5px;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border: 2px solid #df3a45;
	border-radius: 2em;
	background-color: #e76e76;
	opacity: 1;
}

@media (max-width: 991px) {
	.inside-main-content-nested-column-content-wrap img {
		height: auto;
		max-width: 100%;
	}

	.left-nav-mobile-reveal {
		overflow: hidden;
		height: 0px;
		margin-right: -15px;
		margin-left: -15px;
		box-shadow: inset 0 -1px 0 0 #d9e3ec;
	}

	.left-nav-reveal-button-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: -15px;
		margin-left: -15px;
		background-color: rgba(243, 246, 249, 0.5);
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.left-nav-column-wrap {
		padding-bottom: 0;
		padding-top: 0;
		border-right: 0px;
	}

	.left-nav-list-title-link-wrap {
		margin-bottom: 0px;
		margin-top: 0px;
		margin-left: 0px;
	}

	.left-nav-list-title {
		padding-left: 0.75rem;
	}

	.left-nav-list-title-link-wrap {
		background: none;
		color: #354049;
		background-color: #e8edef;
	}

	.left-nav-list,
	.left-nav-list.left-nav-nested-list {
		margin: 0px;
		padding-bottom: 0px;
	}

	.left-nav-list.left-nav-nested-list {
		box-shadow: none;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}

	.left-nav-reveal-button-wrap {
		padding-right: 20px;
		padding-left: 12px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.left-nav-reveal-button-text {
		font-size: 1.1875em;
		line-height: 1em;
	}
}

/* Right sidebar */
.inside-right-sidebar-section-wrap .box {
	width: 100%;
	margin-bottom: 1rem;
}

.inside-right-sidebar-section-wrap .box .contentbox_item_image {
	width: 24px;
}

.inside-right-sidebar-section-wrap .box td {
	padding-bottom: 10px;
}

.inside-right-sidebar-section-wrap .box.documents td:first-child {
	padding-right: 7px;
}

.inside-right-sidebar-section-wrap .box.tools td:first-child img {
	margin-right: 5px;
}

.inside-right-sidebar-section-wrap .box td img {
	max-width: none;
}

.inside-right-sidebar-section-wrap a {
	background-color: #fff;
	color: #c22026;
	cursor: pointer;
}

/* Inside page styles */
#session-message-wrapper {
	margin-top: 50px;
}

#message.success {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 3px 1px 1px;
	border-color: #307820;
}

#message.success p {
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.success ul li {
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.error {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 2px 1px 1px;
	border-color: #b40805;
}

#message.error p {
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.error ul li {
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

table.styled {
	width: 100%;
	margin: 1.5rem 0 1rem 0;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid ghostwhite;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border: none;
}

table.styled thead tr th {
	font-weight: bold;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	background-color: #354049;
	padding: 0.75rem 10px;
	text-align: left;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.inside-main-content-column .inside-content-mine table.styled thead tr th a,
.inside-main-content-column .inside-content-mine table.styled thead tr th a:hover,
.inside-main-content-column .inside-content-mine table.styled th a,
.inside-main-content-column .inside-content-mine table.styled th a:hover {
	color: inherit;
	border: 0px;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	padding: 0.5rem 10px;
	background-color: #c22026;
	color: #ffffff;
	text-align: left;
}

table.styled td {
	padding: 8px 10px;
	color: #696973;
}

table.styled.striped tr:nth-child(even) {
	background: #e8edef;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* Advertisements */
.inside-right-sidebar-column .adshow_container ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* Acrobat link */
.adobe-column {
	display: flex;
	align-items: center;
	min-height: 28px;
}

a.acrobat-reader-link {
	color: #fff;
	font-size: 0.85rem;
	text-decoration: none;
	font-family: 'Roboto Condensed', sans-serif;
	margin-top: 0.3rem;
	transition: all ease 250ms;
	background-color: transparent;
	border-bottom: 1px solid transparent;
}

a.acrobat-reader-link:hover {
	color: #fffbbb;
}

@media (max-width: 991px) {
	a.acrobat-reader-link {
		margin-top: 0.05rem;
	}
}

@media (max-width: 767px) {
	.adobe-column {
		justify-content: center;
	}

	a.acrobat-reader-link {
		margin-top: 0;
	}
}

/* Mega menu */
.nav-dropdown-list.megamenu {
	border-top-color: transparent;
}

.nav-dropdown-list.megamenu.w--open {
	align-items: flex-start;
	border-top-color: #e31822;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	max-height: 700px;
	min-width: 960px;
	position: absolute;
	right: -210px;
	padding-top: 0.5rem;
	padding-bottom: 0rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.megamenu-column-wrap-50 {
	width: 50%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.megamenu-section-wrap-row {
	display: inline-block;
	width: 100%;
}

.megamenu-section-wrap-50 {
	width: 50%;
	float: left;
	margin-bottom: 1.6875rem;
}

.megamenu-section-wrap-50.odd-column {
	padding-right: 0.5rem;
}

.megamenu-section-wrap-50:not(.odd-column) {
	padding-left: 0.5rem;
}


/* blockquote */
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

blockquote {
	margin-bottom: 1em;
	padding: 1em 1.25em;
	border-left: 5px solid #c22026;
	background-color: #e8edef;
	color: #354049;
	font-size: 1.0625em;
	line-height: 1.5em;
	font-weight: 400;
}


/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}

.module-search input[type="search"] {
	box-sizing: border-box;
	padding: 6px;
	border-radius: 4px;
	border: 1px solid #ccc;
	line-height: 1.25em;
	vertical-align: middle;
}
