/*************** Pagination ****************************/
/*******************************************************/
.pagination-wrapper {
	font-size: 0.9375rem;
	padding: 5px;
}

.pagination-wrapper .pagination-page-selection-top {
	width: 50%;
	text-align: left;
}

.pagination-wrapper .pagination-current-items-top {
	float: right;
}

.pagination-wrapper .pagination-current-page select,
.pagination-wrapper .pagination-items-per-page select {
	width: auto !important;
	max-width: 100%;
	height: auto;
	margin-bottom: 0px;
	padding: 5px 10px;
	display: inline;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #666;
	font-family: inherit;
}

/********************* Accounts *************************/
/*******************************************************/
#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
}

#account_module.form .ui-form-field.required .ui-form-label em {
	color: #ee0000;
}

#account_module.form .form_item .label label {
	display: inline;
}

#account_module.form .form_item.required .field,
#account_module.form .form_item .field {
	background: none;
	padding-left: 10px;
	padding-right: 0px;
	padding-bottom: 10px;
}

#account_module.form .required_message span {
	background: none;
}

#account_module.form .form_item.required .label:after,
#account_module.form .required_message span:before {
	content: '*';
	color: #ee0000;
	padding-left: 5px;
	font-style: italic;
	font-weight: 400;
}

#account_module.form .required_message span:before {
	padding-right: 10px;
}

@media (min-width: 991px) {
	#account_module.form .form_container .login_field {
		width: 228px;
	}

	#account_module.form .more_options ul {
		padding-left: 0;
		padding-right: 240px;
		width: 450px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: auto;
	}

	#account_module.form .form_item .field,
	#account_module.form .form_item.required .field {
		padding-left: 0px;
		background: none;
		display: block;
		display: block;
		float: none;
		position: static;
	}

	#account_module.form .ui-form-fields {
		padding-left: 0px;
	}

	#account_module.form .ui-form-field .ui-form-input {
		display: block;
	}

	#account_module.form .ui-form-field .ui-form-label {
		height: auto;
		left: 0px;
		line-height: 1.125rem;
		padding: 6px 6px 6px 0;
		position: static;
		text-align: left;
		top: 6px;
		width: 100%;
	}
}

/*************** ui form styles *************************/
/*******************************************************/
.ui-form-container .ui-form-panel {
	border: 0px;
	padding: 0px !important;
}

.ui-form-container .ui-form-input.ui-form-file {
	font-size: 0.8125rem;
}

.ui-form-container .ui-form-label label {
	font-weight: normal;
}

.ui-form-container .ui-form-field.required .ui-form-label em {
	color: #ee0000;
}

.ui-form-container .ui-form-input.ui-form-file input[type="file"] {
	padding-left: 0px;
	padding-top: 0px;
}

.ui-form-container .ui-form-input.ui-form-textarea textarea {
	border: 1px solid #cccccc;
	color: #333;
}

.ui-form-container .ui-form-fields .select2-choices {
	box-shadow: none;
	border-radius: 0px;
	border: 1px solid #cccccc;
	padding-left: 8px;
}

.ui-form-container .ui-form-fields .select2-choices .select2-input {
	font-size: 0.9375rem;
}

.ui-form-container .ui-form-note {
	line-height: 1.25rem;
}

.ui-form-buttons {
	display: block;
	padding-left: 0px;
	position: static;
	text-align: center;
}

.ui-form-buttons .ui-form-field {
	display: inline-block;
	float: none;
}

.ui-form-buttons .ui-form-field .ui-form-button button {
	margin: 0px;
}

.ui-form-buttons .ui-form-field .ui-form-button a {
	display: inline-block;
	padding: 8px 14px;
}

.ui-form-field .select2-container-multi .select2-choices {
	padding-bottom: 0px;
	padding-top: 0px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding-left: 0px;
	}

	.ui-form-container .ui-form-label,
	.ui-form-container .ui-form-fields,
	.ui-form-container .ui-form-fields .ui-form-field {
		display: block;
		position: static;
		left: auto;
		float: none;
		height: auto;
		width: 100%;
	}

	.ui-form-container .ui-form-input.ui-form-textarea textarea,
	.ui-form-container .ui-form-input.ui-form-text input[type="text"] {
		width: 100% !important;
	}

	.ui-form-container .ui-form-fields .select2-container {
		width: 100% !important;
	}

	.ui-form-container .ui-form-fields .select2-choices {
		width: 100% !important;
	}
}

/****************** Content Modules ***************/
/*************************************************/

/* events */

#events_module.cms_list h3 {
	margin-top: 0px;
}

#events_module.cms_list .event-current-day #event-calendar-link {
	margin-top: 15px;
	display: inline-block;
	width: 100%;
	text-align: right;
}

#events_module #fullcalendar-controls {
	margin-bottom: 15px;
	display: inline-block;
	width: 100%;
}

#events_module.cms_list .event-current-day {
	padding-top: 0px;
}

#events_module.cms_list #categories .category-wrapper {
	display: inline-block;
}

#events_module.cms_list #categories label {
	font-weight: normal;
}

#events_module.cms_list #categories .allCallendars {
	margin-top: 0px;
}

#events_module.cms_list .cms_divider {
	margin-top: 22px;
	margin-bottom: 20px;
}

#events_module.cms_list .cms_date {
	font-size: 1.15em;
	margin-top: 8px !important;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required {
	vertical-align: top;
	display: table-cell !important;
	text-align: inherit !important;
	background-color: transparent;
	font-weight: normal;
}

#events_module.cms_form label {
	font-weight: normal;
}

#events_module.cms_form .cms_field {
	width: 63% !important;
}

#events_module.cms_form .cms_label {
	width: 32% !important;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_entity .cms_description {
	padding-top: 1rem;
}

#events_module.cms_entity .cms_date,
#events_module.cms_entity .cms_location,
#events_module.cms_entity .cms_contact_name,
#events_module.cms_entity .cms_contact_phone,
#events_module.cms_entity .cms_time_comments,
#events_module.cms_entity .cms_registration_closed {
	margin-bottom: 11px;
	padding-bottom: 0px;
}

#events_module.cms_list div.events_module_calendar div.fc-view-container div.fc-view table thead tr th span {
	background-color: #666;
	color: #fff;
	font-family: inherit;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_label,
	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		display: inline-block !important;
		width: auto !important;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 10px !important;
	}

	#events_module.cms_form .cms_field {
		width: 100% !important;
		display: block !important;
	}
}

@media (max-width: 767px) {
	#events_module.cms_list #categories .category-wrapper {
		display: block;
	}
}

/* FAQ */
#faqs_module .cms_list_item ul {
	padding-left: 0px;
}

#faqs_module .cms_list_item .cms_title h3 {
	margin-bottom: 0px;
	margin-top: 0px;
}

#faqs_module.cms_list .cms_item_divider {
	margin-top: 40px;
}

#faqs_module .cms_list_subitem ul {
	list-style-type: initial;
}

/* jobs */
#jobs_module.cms_entity .cms_content {
	padding-top: 0px;
}

#jobs_module.cms_list .cms_title h3,
#jobs_module.cms_list .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 0.375em;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0.5em;
	font-size: 1.25em;
	line-height: inherit;
	color: #002a54;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 20px;
	margin-bottom: 10px;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
}

#jobs_module.cms_form td {
	vertical-align: top;
}

#jobs_module.cms_form .cms_required,
#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field {
	display: table-cell;
}

#jobs_module.cms_form .cms_label {
	width: 30% !important;
}

#jobs_module.cms_form .cms_divider {
	border: none;
}

#jobs_module.cms_form .cms_label {
	font-weight: normal;
}

#jobs_module div.application-for-employment form div.form-fields {
	background: none;
}

#jobs_module div.application-for-employment form div.step {
	width: 100%;
}

#jobs_module div.application-for-employment form div.form-fields,
#jobs_module div.application-for-employment form div.form-field span.x4 textarea {
	width: 100%;
}

#jobs_module div.application-for-employment form div.form-field span.text input,
#jobs_module div.application-for-employment form div.form-field select,
#jobs_module div.application-for-employment form div.form-field span.x4 textarea {
	border-color: #ccc;
}

#jobs_module div.application-for-employment div.tabs ul li {
	color: #333;
}

#jobs_module div.application-for-employment form div.form-field span label {
	width: auto !important;
}

#jobs_module div.application-for-employment div.tabs,
#jobs_module div.application-for-employment div.tabs ul,
#jobs_module div.application-for-employment div.required_field_information {
	width: 100%;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline-block;
		width: auto !important;
	}

	#jobs_module.cms_form .cms_required {
		width: 10px !important;
		padding-top: 0px;
	}

	#jobs_module.cms_form .cms_field {
		display: block;
	}

	#jobs_module div.application-for-employment form div.form-field span.text.x2 {
		width: 100%;
	}
}

/* news */
#news_module.cms_list .cms_list_item .cms_title h3,
#news_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
	font-size: 1.15rem;
}

#news_module.cms_list .cms_list_item .cms_date {
	width: 33%;
}

#news_module.cms_list .cms_item_divider {
	margin-top: 24px;
	margin-bottom: 10px;
}

#news_module.cms_entity .cms_item_divider {
	display: inline-block;
	width: 100%;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 5px;
}

.news_module.cms_category_list.header .cms_category_header_image {
	margin-bottom: 15px;
}

@media (max-width: 991px) {
	#news_module.cms_list .cms_list_item table td,
	#news_module.cms_list .cms_list_item.left,
	#news_module.cms_list .cms_list_item.right {
		display: block;
		width: 100%;
		padding-left: 0px;
	}

	#news_module.cms_list .cms_list_item .cms_date h3 {
		font-size: 1.2rem;
	}
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		margin-right: 0px;
	}
}


/* partners */
#partners_module.cms_list .cms_item_divider {
	margin-bottom: 30px;
	margin-top: 30px;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image img {
	max-width: none;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border: 0px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list {
	margin-top: 0px;
}

#photoalbums_module.cms_list .cms_list_item {
	display: inline-block;
	width: 100%;
	padding-bottom: 10px;
	margin-top: 22px;
}

#photoalbums_module.cms_list .cms_list_item:first-child {
	margin-top: 0px;
}

#photoalbums_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image img {
	max-width: none;
}

#photoalbums_module.cms_list .cms_list_item .separator {
	line-height: 0;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
#moduleDocuments_module.cms_list .cms_items h4 {
	margin-top: 0.2rem;
}

#moduleDocuments_module.cms_list .cms_items h4 a {
	margin-left: 0px;
}

#moduleDocuments_module.cms_list .cms_title .icon {
	margin-bottom: 4px;
}

#moduleDocuments_module.cms_list .cms_title {
	color: #c22026;
	font-family: inherit;
}

#moduleDocuments_module.cms_list .cms_description {
	background-position: 0px 6px;
}

#moduleDocuments_module.cms_list .cms_description {
	padding-left: 25px;
}

/* submission forms */
#submissionforms_module table tr[data-required="true"] .cms_label::after,
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
}

#submissionforms_module.cms_form .cms_buttons {
	margin-left: 3px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	color: #333;
}

#submissionforms_module.cms_form .formSection,
.module-forms .formSection {
	color: #333;
	font-family: inherit;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	font-weight: normal;
}

#submissionforms_module.cms_form .sigNav {
	bottom: 28px;
	margin-left: 267px;
	position: relative;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"] {
	font-size: 0.8125rem;
}

#submissionforms_module .pad {
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigWrapper {
	display: block !important;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_divider {
		display: none;
	}

	#submissionforms_module.cms_form .cms_label {
		display: inline-block;
		padding: 0;
		position: relative;
		text-align: left;
		width: auto;
	}

	#submissionforms_module.cms_form tr[data-required="true"] .cms_label::after {
		content: '*';
		color: #ee0000;
		padding-left: 5px;
		font-style: italic;
		font-weight: 400;
	}

	#submissionforms_module.cms_form .cms_required {
		display: none;
	}

	#submissionforms_module.cms_form .cms_field {
		display: block;
		padding: 6px 0 0;
		width: 100%;
	}

	.module-forms.confirmation table td {
		display: block;
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	#submissionforms_module.cms_form .checkbox_wrapper > table > tbody > tr > .checkbox-cell {
		display: inline;
	}
}

@media screen and (max-width: 380px) {
	#submissionforms_module.cms_form .sigNav {
		bottom: auto;
		float: right;
		margin-left: 0px;
		position: relative;
		margin-right: 18px;
		margin-top: -24px;
	}
}

/* date picker */
#submissionforms_module.cms_form .ui-datepicker-trigger {
	margin-left: 3px;
	position: relative;
	top: -5px;
	left: 0px;
}

.ui-datepicker .ui-datepicker-title select,
.ui-datepicker td span,
.ui-datepicker td a,
.ui-datepicker th {
	font-size: 0.75rem;
}

.inside-content-mine input.hasDatepicker {
	display: inline-block !important;
}


/* testimonials */
#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-bottom: 25px;
	margin-top: 25px;
}

#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}


/*************** Dynamic content boxes styling ***************/
/******* Events *******/
#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox a {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #696973;
	text-decoration: none;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
}

#events_module.homepage_contentbox a:hover {
	color: #c22026;
}

#events_module.homepage_contentbox .event_list {
	padding: 0.5em 0;
	margin-bottom: 0.5em;
	border-bottom: 1px solid #ccc;
}

#events_module.homepage_contentbox .event_list a {
	border-bottom: 1px solid;
	border-bottom-color: rgba(84, 159, 196, 0.3);
}

#events_module.homepage_contentbox .event_list a:hover {
	border-bottom-color: rgba(93, 116, 140, 0.6);
}

#events_module.homepage_contentbox .footer {
	display: none;
}

#events_module.homepage_contentbox .metadata2.date {
	font-weight: 700;
	color: #354049;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

#events_module.homepage_contentbox .cms_description {
	font-size: 0.875rem;
	line-height: 1.5em;
}

#events_module.homepage_contentbox .cms_description h1,
#events_module.homepage_contentbox .cms_description h2,
#events_module.homepage_contentbox .cms_description h3,
#events_module.homepage_contentbox .cms_description h4,
#events_module.homepage_contentbox .cms_description h5,
#events_module.homepage_contentbox .cms_description h6 {
	font-size: 0.875rem;
	margin: 0;
	padding: 0;
	background-image: none;
	font-weight: normal;
	line-height: 1.5rem;
	font-weight: 700;
	color: #62686e;
	font-family: Roboto, sans-serif;
}

/******* News *******/
#news_module.homepage_contentbox .cms_list_item {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
}

#news_module.homepage_contentbox .cms_list_item h3 {
	margin: 0.375em 0px;
	padding: 0;
	background-image: none;
	font-size: 1rem;
	line-height: 1.5em;
}

#news_module.homepage_contentbox .cms_list_item h3 a {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #696973;
	text-decoration: none;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox .cms_list_item h3 a:hover {
	color: #c22026;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	font-weight: 700;
	color: #354049;
	font-size: 0.875rem;
	font-family: Roboto, sans-serif;
}

#news_module.homepage_contentbox .cms_list_item .cms_description {
	margin: 0;
	color: #696973;
	font-size: 0.875rem;
	line-height: 1.5em;
}

#news_module.homepage_contentbox .cms_list_item .cms_description a {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #356aa0;
	text-decoration: none;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-weight: 400;
}

#news_module.homepage_contentbox .cms_list_item .cms_description a:hover {
	color: #b8131b;
}

#news_module.homepage_contentbox > a {
	display: none;
}

/******* Partners *******/
#partners_module .cycle-slideshow-carousel .partner-carousel-slide {
	padding: 0 15px;
}

/******* People directory *******/
.homesection .collection.users-collection .node.users-node {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
}

.homesection .collection.users-collection .node.users-node .image p {
	margin: 0;
}

.homesection .collection.users-collection .node.users-node .date p {
	font-weight: 700;
	color: #62686e;
	font-size: 0.875rem;
	font-family: Roboto, sans-serif;
	margin: 0;
}

.homesection .collection.users-collection .node.users-node .name p {
	margin: 0;
}

.homesection .collection.users-collection .node.users-node .name p strong {
	font-weight: 400;
}

.homesection .collection.users-collection .node.users-node .name p strong a {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #356aa0;
	text-decoration: none;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-weight: 400;
}

.homesection .collection.users-collection .node.users-node .name p strong a:hover {
	color: #b8131b;
}

.homesection .collection.users-collection .node.users-node .type p {
	font-weight: 700;
	color: #62686e;
	font-size: 0.875rem;
	font-family: Roboto, sans-serif;
	margin: 0;
}

/******* Submission forms *******/
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
	font-weight: 400;
}

#submissionforms_module.homepage_contentbox .cms_label em {
	display: none;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/******* Testimonials *******/
#testimonials_module_contentbox.contentbox_item .image-box {
	margin-bottom: 10px;
	margin-top: 10px;
}

#testimonials_module_contentbox.contentbox_item .author-name {
	font-weight: 700;
	margin-top: 0.5em;
}

#testimonials_module_contentbox.contentbox_item .company {
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 1.25em;
	margin: 0.5em 0;
	text-transform: uppercase;
}

#testimonials_module_contentbox + script + .button,
#testimonials_module_contentbox + script + .content-box-learn-more-link {
	display: none;
}
