/***** Ensure that all information conveyed with color is also available without color *******/
/*********************************************************************************************/
/* /core/libraries/jquery.mmenu/assets/7.0.3/jquery.mmenu.all.min.css */
.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.mm-counter {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-iconbar,
.mm-iconbar a {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-panel__noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-sectionindexer a {
	color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.mm-menu_theme-white .mm-navbar a,
.mm-menu_theme-white .mm-navbar > * {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-menu_theme-white .mm-counter {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-menu_theme-white .mm-iconbar,
.mm-menu_theme-white .mm-iconbar a {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-menu_theme-white .mm-panel__noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-style: italic;
}

.mm-menu_theme-white .mm-sectionindexer a {
	color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.mm-menu_theme-dark .mm-navbar a,
.mm-menu_theme-dark .mm-navbar > * {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-dark .mm-counter {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-dark .mm-iconbar,
.mm-menu_theme-dark .mm-iconbar a {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-dark .mm-panel__noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-dark .mm-sectionindexer a {
	color: rgba(255, 255, 255, 0.4);
	cursor: pointer;
}

.mm-menu_theme-black .mm-navbar a,
.mm-menu_theme-black .mm-navbar > * {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-black .mm-counter {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-black .mm-iconbar,
.mm-menu_theme-black .mm-iconbar a {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-black .mm-panel__noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-style: italic;
}

.mm-menu_theme-black .mm-sectionindexer a {
	color: rgba(255, 255, 255, 0.4);
	cursor: pointer;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: bold;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: bold;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	font-weight: bold;
}

.fc-unthemed .fc-list-empty { /* theme will provide own background */
	background-color: #eee;
	font-weight: bold;
}


/***** Removing the underline from links makes it hard for color-blind users to see them *******/
/*********************************************************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/* /core/libraries/jquery.mmenu/assets/7.0.3/jquery.mmenu.all.min.css */
.mm-listitem a,
.mm-listitem a:hover {
	text-decoration: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/***** Ensure that foreground and background colors have enough contrast ****/
/****************************************************************************/
/* /core/libraries/jquery.mmenu/assets/7.0.3/jquery.mmenu.all.min.css */
.mm-searchfield input {
	background: #696969;
	color: #ffffff;
}

.mm-menu_theme-white .mm-searchfield input {
	background: #696969;
	color: #ffffff;
}

.mm-menu_theme-dark .mm-searchfield input {
	background: #696969;
	color: #ffffff;
}

.mm-menu_theme-black .mm-searchfield input {
	background: #696969;
	color: #ffffff;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #5e5e5e;
	color: #ffffff;
	font-family: inherit;
}

/********* Use relative rather than absolute units in CSS property values. **********/
/************************************************************************************/
/* /core/libraries/jquery.mmenu/assets/7.0.3/jquery.mmenu.all.min.css */
.mm-sectionindexer {
	font-size: 0.75rem;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}
